import React from 'react';
import styles from './index.module.scss';
import { Field, Form, ErrorMessage } from 'formik';
import { useFormikContext } from 'formik';

const Content = (props) => {
    const { values } = useFormikContext();

    return (
        <Form className={styles.formAdminLogin}>
            <h3>Innovation Tech Login</h3>
            <label>username<ErrorMessage name="username" render={msg => <span className="error">{msg}</span>} /></label>
            <Field name="username" className={styles.inputText} placeholder="" />

            <label>password<ErrorMessage name="password" render={msg => <span className="error">{msg}</span>} /></label>
            <Field name="password" type="password" className={styles.inputText} placeholder="" />

            {values.checkLogin && <p className={`error ${styles.marginBottom}`}>username หรือ password ไม่ถูกต้อง</p>}
            <button type="submit">เข้าสู่ระบบ</button>
        </Form>
    )
};

export default Content;
