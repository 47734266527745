import React from 'react';
import { Router } from 'react-router-dom';
import FrontEnd from './js/route.js';
import history from './js/history'
import './css/bootstrap.css'
import './css/style.css'
import './vender/fontawesome/css/all.min.css'
function App() {
  return (
    <Router history={history}>
      <FrontEnd />
    </Router>
  );
}

export default App;