import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Plus from "../../../images/plus.svg";
import Dot from "../../../images/dot.svg";
import axios from "axios";
import { API_URL_DATABASE } from "../../config_url.js";

const NavSideBar = (props) => {
  const [listCustomer, setListCustomer] = useState([]);
  const [removeDone, setRemoveDone] = useState(false);

  const [, setShow] = useState(false);

  // Fetch Costimer
  useEffect(() => {
    axios
      .get(
        `${API_URL_DATABASE}/customer-voc`
        // , { headers: { "authorization": localStorage.getItem('token_auth') } }
      )
      .then((res) => {
        console.log("customer-voc res", res);
        setListCustomer(res.data);
        setRemoveDone(false);
      })
      .catch((err) => {
        console.log("err", err.response);
      });
  }, [removeDone]);

  const sendProps = (coNameEN) => {
    let list_customer = listCustomer.find(
      (listCustomer) => `${listCustomer.coNameEN}` === `${coNameEN}`
    );
    if (list_customer) {
      // console.log("list_customer", list_customer)
      props.valueCompony(list_customer);
    }
  };

  const removeCustomer = (index) => {
    axios
      .delete(`${API_URL_DATABASE}/customer-voc/${index}`, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
        // console.log("res", res.data)
        setRemoveDone(true);
      })
      .catch((err) => {
        console.log("err >>>", err.response);
      });
  };

  return (
    <ul
      className="navbar-nav sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      {/* <-- Sidebar - Brand --> */}
      <div
        className="sidebar-brand d-flex align-items-center justify-content-center"
        style={{ border: "none", background: "none" }}
      >
        <div className="sidebar-brand-text font-en">List Customer</div>
        <div className="sidebar-brand-icon">
          <Link
            to="/create-customer"
            style={{ border: "none", background: "none" }}
            onClick={() => setShow(true)}
          >
            <img alt="plus" src={Plus} width="30px" />
          </Link>
        </div>
      </div>

      {/* <-- Nav Item - Dashboard --> */}
      {listCustomer.map((name, index) => {
        return (
          <li className="nav-item active" key={index} id={index}>
            <div className="nav-link">
              <button
                type="button"
                onClick={(e) => sendProps(name.coNameEN)}
                style={{
                  overflow: "hidden",
                  border: "none",
                  background: "none",
                }}
              >
                <img
                  alt="plus"
                  src={Dot}
                  width="10px"
                  style={{ marginRight: "5px" }}
                />
                <span>{name.coNameEN.slice(0, 9)}</span>
              </button>
              <button
                type="button"
                className="icon-nav"
                onClick={(e) => {
                  if (window.confirm("คุณต้องการลบหรือไม่?"))
                    removeCustomer(name.index);
                }}
                style={{ border: "none", background: "none", padding: "2px" }}
              >
                <i className="fas fa-times" style={{ color: "#EB5757" }}></i>
              </button>
              <Link
                className="icon-nav"
                to={`/create-customer?index=${name.index}&coNameTH=${name.coNameTH}&coNameEN=${name.coNameEN}&email=${name.email}&phone=${name.phone}&name=${name.name}`}
                style={{ border: "none", background: "none", padding: "2px" }}
              >
                <i className="fas fa-edit" style={{ color: "#F2994A" }}></i>
              </Link>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default NavSideBar;
