import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from './components/main';
import FromVoc from './components/from-voc';
import CreateCustomer from './components/create-customer';
import ThxPage from './components/thx-page';
import PreviewEmail from './components/preview-email';
import Complaining from './components/complaining';
import NotFoundComponent from './components/404-not-found';
import LoginComponent from './components/login';
import EditQuestionsComponent from './components/edit-questions';

const FrontEnd = () => (
    <Switch>
        {/* Wrap the routes in a Switch which only renders the first matched component. Otherwise you would see multiple components rendered. */}
        <Route exact path="/" component={Main} />
        <Route exact path="/from-voc" component={FromVoc} />
        <Route exact path="/create-customer" component={CreateCustomer} />
        <Route exact path="/thx-page" component={ThxPage} />
        <Route exact path="/preview-email" component={PreviewEmail} />
        <Route exact path="/complaining" component={Complaining} />
        <Route exact path="/login" component={LoginComponent} />
        <Route exact path="/customize/questions" component={EditQuestionsComponent} />

        <Route component={NotFoundComponent} />
    </Switch>
);

export default FrontEnd;