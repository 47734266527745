import React from 'react';
import { useFormikContext } from 'formik';
import { Field, Form, ErrorMessage } from 'formik';
import Logo from '../../../images/logo-inno.png'
import { Redirect, useHistory } from 'react-router-dom';

const Content = () => {
    const { values, setFieldValue } = useFormikContext();

    let history = useHistory();
    if (values.checkSuccess) {
        return <Redirect to="/thx-page"></Redirect>
    } else {
        return (
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 font-en font-white" style={{ fontWeight: "bold" }}>
                        <img src={Logo} alt="Girl in a jacket" width="80" style={{ borderRadius: "8px", marginRight: "10px" }} />ใบบันทึกข้อร้องเรียน</h1>
                    <div className="d-none d-sm-inline-block btn btn-sm btn-bgblue-black shadow-sm"><i className="far fa-calendar mr-2"></i>{values.date_string}</div>
                </div>

                <Form>
                    <div className="row" style={{ justifyContent: "center" }}>

                        {/* Header Left */}
                        <div className="col-xl-9 col-md-12" >
                            <div className="card shadow mb-4">
                                {/* <!-- Card Header - Dropdown --> */}
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary font-en">ข้อร้องเรียนลูกค้า</h6>
                                    <div className="dropdown no-arrow">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </div>
                                </div>
                                {/* <!-- Card Body --> */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-3 col-md-12 mb-2">
                                            <label className="font-weight-bold font-blue">วันที่ : 
                                            <ErrorMessage name="dateComplaint" render={msg => <span className="error">{msg}</span>} /></label>
                                        </div>
                                        <div className="col-xl-9 mb-2">
                                            <Field className="w-100" type="date" name="dateComplaint"></Field>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-3 col-md-12 mb-2">
                                            <label className="font-weight-bold font-blue">ชื่อผู้ร้องเรียน :
                                            <ErrorMessage name="nameComplaint" render={msg => <span className="error">{msg}</span>} /></label>
                                        </div>
                                        <div className="col-xl-9 mb-2">
                                            <Field className="w-100" type="text" name="nameComplaint"></Field>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-3 col-md-12 mb-2">
                                            <label className="font-weight-bold font-blue">เบอร์โทรศัพท์ :
                                            <ErrorMessage name="phoneComplaint" render={msg => <span className="error">{msg}</span>} /></label>
                                        </div>
                                        <div className="col-xl-9 mb-2">
                                            <Field className="w-100" type="text" name="phoneComplaint"></Field>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-3 col-md-12 mb-2">
                                            <label className="font-weight-bold font-blue">รายละเอียดที่ร้องเรียน :
                                            <ErrorMessage name="detailComplaint" render={msg => <span className="error">{msg}</span>} /></label>
                                        </div>
                                        <div className="col-xl-9 mb-2">
                                            <Field className="w-100" as="textarea" name="detailComplaint" className="edit w-100" style={{ resize: "none" }} rows="6"></Field>
                                        </div>
                                    </div>

                                    <button type="submit" className="btn-blue float-right px-5">บันทึก</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </Form>

            </div>
        )
    }
};

export default Content; 