/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { withFormik, useFormikContext } from 'formik';
import Content from "./content.js";
import axios from "axios";
import { Redirect } from 'react-router-dom';
import { API_URL_DATABASE } from '../../config_url.js';

const CreateCustomer = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        let url = window.location.search;
        const urlParams = new URLSearchParams(url);
        setFieldValue("index", urlParams.get('index'), false);
        setFieldValue("coNameTH", urlParams.get('coNameTH'), false);
        setFieldValue("coNameEN", urlParams.get('coNameEN'), false);
        setFieldValue("email", urlParams.get('email'), false);
        setFieldValue("name", urlParams.get('name'), false);
        setFieldValue("phone", urlParams.get('phone'), false);
    }, []);

    useEffect(() => {
        axios.get(`${API_URL_DATABASE}/customer-voc`, { headers: { "authorization": localStorage.getItem('token_auth') } })
            .then((res) => {
                let url = window.location.search;
                const urlParams = new URLSearchParams(url);
                const test = res.data.sort((a, b) => parseInt(b.index)-parseInt(a.index))

                const lastIndex = parseInt(test[0].index)
                setFieldValue("lastIndex", lastIndex + 1, false);
                setFieldValue("has_PO", res.data.find((ele) => ele.index === urlParams.get('index')).has_PO, false);
            })
            .catch((err) => {
                console.log("err", err.response)
            });
    }, []);

    if (!localStorage.getItem('token_auth')) {
        return <Redirect to='/login'></Redirect>
    } else {
        return (
            <div id="wrapper">
                {/* <NavSideBar /> */}
                {/* {/*<!-- Content Wrapper -->*/}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/*<!-- Begin Page Content -->*/}
                    <div id="content" className="container-fluid bg-page"><Content /></div>
                    {/*<!-- /.container-fluid -->*/}
                </div>
            </div>
        )
    }
};


const date = new Date();
const ye = date.getFullYear();
const moString = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
const da = date.getDate();

const EnhancedCreateCustomer = withFormik({
    mapPropsToValues: () => ({
        coNameTH: '',
        coNameEN: '',
        email: '',
        name: '',
        phone: '',
        has_PO: {},
        date_string: `${da}-${moString}-${ye}`,

        checkSuccess: false,
        lastIndex: 0
    }),
    validate: values => {
        const errors = {};

        if (!values.coNameTH) {
            errors.coNameTH = "*"
        }
        if (!values.coNameEN) {
            errors.coNameEN = "*"
        }
        if (!values.email) {
            errors.email = "*"
        }
        if (!values.name) {
            errors.name = "*"
        }
        if (!values.phone) {
            errors.phone = "*"
        }

        return errors;
    },
    handleSubmit: (values, { setFieldValue }) => {
        if (!values.index) {
            const user = {
                "index": values.lastIndex.toString(),
                "coNameTH": values.coNameTH,
                "coNameEN": values.coNameEN,
                "name": values.name,
                "phone": values.phone,
                "email": values.email,
                "isRegular": 1,
                "has_PO": {}
            };
            console.log(user)
            axios.post(`${API_URL_DATABASE}/customer-voc`, user, { headers: { "authorization": localStorage.getItem('token_auth') } })
                .then(res => {
                    console.log(res);
                    setFieldValue("checkSuccess", true, false);
                }).catch(function (err) {
                    console.log("err", err.response)
                });
        } else { // UPDATE DATA
            console.log("update data");
            const user = {
                "index": values.index.toString(),
                "coNameTH": values.coNameTH,
                "coNameEN": values.coNameEN,
                "name": values.name,
                "phone": values.phone,
                "email": values.email,
                "isRegular": 1,
                "has_PO": values.has_PO
            };
            axios.put(`${API_URL_DATABASE}/customer-voc/${values.index}`, user, { headers: { "authorization": localStorage.getItem('token_auth') } })
                .then(res => {
                    console.log(res);
                    setFieldValue("checkSuccess", true, false);
                }).catch(function (err) {
                    console.log("err", err.response)
                })
        }
    }
})(CreateCustomer);

export default EnhancedCreateCustomer;