/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Redirect, useHistory, Link } from "react-router-dom";

import ProfileCo from "../../../images/profileCo.svg";
import { useFormikContext } from "formik";
import { Field, Form, ErrorMessage } from "formik";
import { ExportCSV } from "../common/export-xlsx.js";
import axios from "axios";
import { API_URL_DATABASE } from "../../config_url.js";

const Content = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const [listResponseVoc, setListResponseVoc] = useState([]);
  let history = useHistory();

  var start_date = new Date();
  start_date.setMonth(start_date.getMonth() - 1);
  var end_date = new Date();
  end_date.setDate(end_date.getDate() + 1);
  const [startDate, setStartDate] = useState(
    start_date.toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(end_date.toISOString().slice(0, 10));
  const [startDateForComplaint, setStartDateForComplaint] = useState(
    start_date.toISOString().slice(0, 10)
  );
  const [endDateForComplaint, setEndDateForComplaint] = useState(
    end_date.toISOString().slice(0, 10)
  );

  // SET VALUES
  useEffect(() => {
    if (props.listCustomer.index) {
      console.log("props.listCustomer", props.listCustomer);
      setFieldValue("_id", props.listCustomer.index, false);
      setFieldValue("coNameTH", props.listCustomer.coNameTH, false);
      setFieldValue("coNameEN", props.listCustomer.coNameEN, false);
      setFieldValue("email", props.listCustomer.email, false);
      setFieldValue("name", props.listCustomer.name, false);
      setFieldValue("phone", props.listCustomer.phone, false);
    }
  }, [props.listCustomer]);

  const clearState = () => {
    setFieldValue("_id", "", false);
    setFieldValue("coNameTH", "", false);
    setFieldValue("coNameEN", "", false);
    setFieldValue("email", "", false);
    setFieldValue("name", "", false);
    setFieldValue("phone", "", false);
  };
  // console.log(">>>>>>>", values._id)
  // Fetch response
  useEffect(() => {
    axios
      .get(
        `${API_URL_DATABASE}/response-voc?startDate=${startDate}&endDate=${endDate}`
        // , { headers: { "authorization": localStorage.getItem('token_auth') } }
      )
      .then((res) => {
        console.log("response-voc res.data", res.data);
        let dataConvertKey = [];
        let total = 0;
        let count_index_point = 0;
        res.data.map((data) => {
          if (data.responses.length > 0) {
            // console.log("data.responses[0]", data.responses[0])
            let pointEnd = 0;
            let index = 0;
            Object.keys(data.responses[0]).filter((keyName) => {
              let point = keyName.search("คะแนน");
              if (point >= 0) {
                pointEnd = pointEnd + data.responses[0][keyName];
                index = index + 1;
              }
            });

            total = total + pointEnd / index;
            count_index_point = count_index_point + 1;

            data.responses[0].refPO = data.refPO
            delete data.responses[0]._id;
            delete data.responses[0].receivedOnDatetime;
            dataConvertKey.push(data.responses[0]);
          } else {
            return;
          }
        });

        setFieldValue(
          "total",
          total ? parseFloat(total / count_index_point).toFixed(2) : "-",
          false
        );
        setFieldValue("countPeople", count_index_point, false);
        setListResponseVoc(dataConvertKey);
      })
      .catch((err) => {
        console.log("response-voc err", err.responses);
      });
  }, [startDate, endDate]);

  // Fetch response ForComplaint
  useEffect(() => {
      axios.get(`${API_URL_DATABASE}/customer-complaining?startDate=${startDateForComplaint}&endDate=${endDateForComplaint}`, { headers: { "authorization": localStorage.getItem('token_auth') } })
          .then((res) => {
              console.log("res", res.data);
              setFieldValue("listComplaint", res.data, false);
              setFieldValue("numberOfComplaint", res.data.length, false);
          })
          .catch((err) => {
              console.log("err", err)
          });
  }, [startDateForComplaint, endDateForComplaint]);

  if (!localStorage.getItem("token_auth")) {
    return <Redirect to="/login"></Redirect>;
  } else {
    return (
      <div>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1
            className="h3 mb-0 font-en font-white"
            style={{ fontWeight: "bold" }}
          >
            <img alt="plus" src={ProfileCo} width="30px" className="mr-4" />
            Profile Company <span style={{ fontSize: '10px', color: '#FFFFFF' }}>(Version AWS)</span>
          </h1>
          <div className="d-none d-sm-inline-block btn btn-sm btn-bgblue-black shadow-sm">
            <i className="far fa-calendar mr-2"></i>
            {values.date_string}
            <button
              type="button"
              className="ml-3 btn-bgblue-black"
              onClick={() => {
                localStorage.removeItem("token_auth");
                history.push("/login");
              }}
            >
              Logout
            </button>
          </div>
        </div>

        <div className="row">
          <div className="float-left col-xl-7 col-lg-12">
            <div className="row">
              {/* From Customer */}
              <div className="col-12">
                <div className="card shadow mb-4">
                  {/* <!-- Card Header - Dropdown --> */}
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary font-en">
                      Survey Customer
                    </h6>
                    <div className="dropdown no-arrow">
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </div>
                  </div>
                  {/* <!-- Card Body --> */}
                  <div className="card-body">
                    <Form>
                      <div className="row">
                        <div className="col-xl-4 col-lg-12 mb-2">
                          <label className="font-weight-bold font-blue">
                            ชื่อบริษัท/Co. Name:
                            <ErrorMessage
                              name="coNameTH"
                              render={(msg) => (
                                <span className="error">{msg}</span>
                              )}
                            />
                            <ErrorMessage
                              name="coNameEN"
                              render={(msg) => (
                                <span className="error">{msg}</span>
                              )}
                            />
                          </label>
                        </div>
                        <div className="col-xl-8 mb-2">
                          <Field
                            className="w-100"
                            type="text"
                            name="coNameTH"
                            placeholder="ภาษาไทย"
                            disabled={values._id ? true : false}
                          ></Field>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-lg-12 mb-2"></div>
                        <div className="col-xl-8 mb-2">
                          <Field
                            className="w-100"
                            type="text"
                            name="coNameEN"
                            placeholder="ภาษาอังกฤษ"
                            disabled={values._id ? true : false}
                          ></Field>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-lg-12 mb-2">
                          <label className="font-weight-bold font-blue">
                            อีเมลล์/Co. Email:
                            <ErrorMessage
                              name="email"
                              render={(msg) => (
                                <span className="error">{msg}</span>
                              )}
                            />
                          </label>
                        </div>
                        <div className="col-xl-8 mb-2">
                          <Field
                            className="w-100"
                            type="email"
                            name="email"
                            placeholder="example@example.com"
                            disabled={values._id ? true : false}
                          ></Field>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-lg-12 mb-2">
                          <label className="font-weight-bold font-blue">
                            ชื่อลูกค้า/Name
                            <ErrorMessage
                              name="name"
                              render={(msg) => (
                                <span className="error">{msg}</span>
                              )}
                            />
                          </label>
                        </div>
                        <div className="col-xl-8 mb-2">
                          <Field
                            className="w-100"
                            type="text"
                            name="name"
                            placeholder="..."
                            disabled={values._id ? true : false}
                          ></Field>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-lg-12 mb-2">
                          <label className="font-weight-bold font-blue">
                            เบอร์โทรศัพท์/Phone No:
                            <ErrorMessage
                              name="phone"
                              render={(msg) => (
                                <span className="error">{msg}</span>
                              )}
                            />
                          </label>
                        </div>
                        <div className="col-xl-8 mb-2">
                          <Field
                            className="w-100"
                            type="text"
                            name="phone"
                            placeholder="XXX-XXX-XXXX"
                            disabled={values._id ? true : false}
                          ></Field>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-lg-12 mb-2 ">
                          <label className="font-weight-bold font-blue">
                            อ้างอิงใบสั่งซื่อ/Ref PO:
                            <ErrorMessage
                              name="refPO"
                              render={(msg) => (
                                <span className="error">{msg}</span>
                              )}
                            />
                          </label>
                        </div>
                        <div className="col-xl-8 mb-2">
                          <Field
                            className="w-100"
                            type="text"
                            name="refPO"
                            placeholder="POXXXXXX-XXXX"
                          ></Field>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-4 col-lg-12 mb-2">
                          <button
                            type="button"
                            className="btn-green float-left px-4"
                            onClick={() => history.push("/customize/questions")}
                          >
                            Edit Questions
                          </button>
                        </div>
                        <div className="col-xl-8 mb-2">
                          <button
                            type="submit"
                            className="btn-blue float-right px-4"
                          >
                            Preview
                          </button>
                          <button
                            type="submit"
                            className="btn-red float-right px-4 mr-2"
                            onClick={() => clearState()}
                          >
                            Clear Msg
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="float-right col-xl-5 col-lg-12">
            <div className="row">
              {/* Dashboard */}
              <div className="col-12">
                <div className="card shadow mb-4">
                  {/* <!-- Card Header - Dropdown --> */}
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary font-en">
                      Dashboard VOC
                    </h6>
                    <div className="dropdown no-arrow">
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </div>
                  </div>
                  {/* <!-- Card Body --> */}
                  <div className="card-body">
                    <div className="col-12">
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                      >
                        <h1
                          className="mb-0"
                          style={{ color: "#1B39A5", fontWeight: "bold" }}
                        >
                          {values.total}
                        </h1>
                        <span className="ml-2">
                          คะแนน ทั้งหมด {values.countPeople} คน
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Export Xlsx */}
              <div className="col-12">
                <div className="card shadow mb-4">
                  {/* <!-- Card Header - Dropdown --> */}
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary font-en">
                      Export File .xlsx
                    </h6>
                    <div className="dropdown no-arrow">
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </div>
                  </div>
                  {/* <!-- Card Body --> */}
                  <div className="card-body">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-xl-5 col-lg-12 mb-2">
                          <label className="font-weight-bold font-blue">
                            เริ่มต้น/Start Date:
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-12 mb-2">
                          <Field
                            className="w-100"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          ></Field>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-5 col-lg-12 mb-2">
                          <label className="font-weight-bold font-blue">
                            สิ้นสุด/End Date:
                          </label>
                        </div>
                        <div className="col-xl-7 col-lg-12 mb-2">
                          <Field
                            className="w-100"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                          ></Field>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <ExportCSV
                        csvData={listResponseVoc}
                        fileName="response-voc"
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="float-left col-xl-9 col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary font-en">
                      Customer Complaint
                      <label className="small ml-4 mr-1 mb-0">
                        Start Date:
                      </label>
                      <Field
                        className="small mr-2"
                        type="date"
                        value={startDateForComplaint}
                        onChange={(e) =>
                          setStartDateForComplaint(e.target.value)
                        }
                      ></Field>
                      <label className="small ml-4 mr-1 mb-0">End Date:</label>
                      <Field
                        className="small"
                        type="date"
                        value={endDateForComplaint}
                        onChange={(e) => setEndDateForComplaint(e.target.value)}
                      ></Field>
                    </h6>
                    <div className="dropdown no-arrow">
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </div>
                  </div>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>เลขที่ใบข้อร้องเรียน</th>
                          <th>ชื่อผู้ร้องเรียน</th>
                          <th>เบอร์ติดต่อ</th>
                          <th>ร้องเรียนวันที่</th>
                          <th>รายละเอียด</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.listComplaint.map((data, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{data.documentNo}</td>
                              <td>{data.nameComplaint}</td>
                              <td>{data.phoneComplaint}</td>
                              <td>{data.send_date}</td>
                              <td>{data.detailComplaint}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="float-left col-xl-3 col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary font-en">
                      Number of Complaints
                    </h6>
                    <div className="dropdown no-arrow">
                      <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                    </div>
                  </div>
                  <div className="card-body" style={{ overflowX: "scroll" }}>
                    <div className="col-12">
                      <div
                        className="row"
                        style={{
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                      >
                        <h1
                          className="mb-0"
                          style={{ color: "#1B39A5", fontWeight: "bold" }}
                        >
                          {values.numberOfComplaint}
                        </h1>
                        <span className="ml-2">เรื่อง</span>
                      </div>

                      <div
                        className="row mt-3"
                        style={{
                          justifyContent: "center",
                          alignItems: "flex-center",
                        }}
                      >
                        <button
                          type="button"
                          onClick={() => history.push("/complaining")}
                          className="btn-blue float-right px-4"
                        >
                          สร้างเอกสาร
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Content;
