import React, { useState, useEffect } from 'react';
import Content from "./content.js";
import { withFormik, useFormikContext } from 'formik';
import { API_URL_DATABASE } from '../../config_url.js';
import axios from "axios";

const Complaining = (props) => {
    const { values, setFieldValue } = useFormikContext();

    var start_date = new Date();
    start_date.setMonth(start_date.getMonth() - 1);
    var end_date = new Date();
    end_date.setDate(end_date.getDate() + 1);
    const [startDate] = useState(start_date.toISOString().slice(0, 10));
    const [endDate] = useState(end_date.toISOString().slice(0, 10));
    useEffect(() => {
        axios.get(`${API_URL_DATABASE}/customer-complaining?startDate=${startDate}&endDate=${endDate}`)
            .then(res => {
                console.log("res", res)
                setFieldValue("documentNo", parseInt(res.data[res.data.length - 1].documentNo) + 1, false);
            }).catch(function (err) {
                console.log("err", err)
            });
    }, [endDate, setFieldValue, startDate]);

    return (
        <div id="wrapper">
            {/* {/*<!-- Content Wrapper -->*/}
            <div id="content-wrapper" className="d-flex flex-column">
                {/*<!-- Begin Page Content -->*/}
                <div id="content" className="container-fluid bg-page"><Content /></div>
                {/*<!-- /.container-fluid -->*/}
            </div>
        </div>
    )
};

const date = new Date();
const ye = date.getFullYear();
const moString = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
const da = date.getDate();
const sendDate = new Date().toISOString().slice(0, 10);

const EnhancedComplaining = withFormik({
    mapPropsToValues: (props) => ({
        checkSuccess: false,

        date_string: `${da}-${moString}-${ye}`,
        sendDate: sendDate,

        documentNo: '',

        // Content
        dateComplaint: sendDate,
        nameComplaint: '',
        phoneComplaint: '',
        detailComplaint: '',
    }),
    validate: values => {
        const errors = {};

        // if (!values.dateComplaint) {
        //     errors.dateComplaint = "*"
        // }
        if (!values.nameComplaint) {
            errors.nameComplaint = "*"
        }
        // if (!values.documentDelivery) {
        //     errors.documentDelivery = "*"
        // }
        if (!values.detailComplaint) {
            errors.detailComplaint = "*"
        }
        // if (!values.companyComplaint) {
        //     errors.companyComplaint = "*"
        // }
        if (!values.phoneComplaint) {
            errors.phoneComplaint = "*"
        }
        // if (!values.documentPO) {
        //     errors.documentPO = "*"
        // }

        return errors;
    },
    handleSubmit: (values, { setFieldValue }) => {
        console.log("values>>>", values);

        let data = {

            "send_date": values.sendDate,
            "documentNo": values.documentNo || new Date().toISOString(),

            // Content
            "dateComplaint": values.dateComplaint,
            "nameComplaint": values.nameComplaint,
            "phoneComplaint": values.phoneComplaint,
            "detailComplaint": values.detailComplaint,
        }
        console.log('data', data)
        axios.post(`${API_URL_DATABASE}/customer-complaining`, data)
            .then(res => {
                console.log(res);
                setFieldValue("checkSuccess", true, false);
            }).catch(function (err) {
                console.log("err", err)
            });
    }
})(Complaining);

export default EnhancedComplaining;