import React, { useEffect, useState } from 'react';
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import { Redirect } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Field } from 'formik';
import { API_URL_DATABASE } from '../../config_url.js';
import Logo from '../../../images/logo-inno.png'

const Content = () => {
    const { values, setFieldValue } = useFormikContext();
    const [checkSumbited, setCheckSumbited] = useState(false);

    useEffect(() => {
        let url = window.location.search;
        const urlParams = new URLSearchParams(url);
        axios.get(`${API_URL_DATABASE}/customer-voc/${urlParams.get('formId')}`)
            .then((res) => {
                setFieldValue("coNameTH", res.data.coNameTH, false);
                setFieldValue("coNameEN", res.data.coNameEN, false);
                setFieldValue("email", res.data.email, false);
                setFieldValue("name", res.data.name, false);
                setFieldValue("phone", res.data.phone, false);
                setFieldValue("refPO", res.data.thisPO, false);
                setFieldValue("formId", urlParams.get('formId'), false);
            })
            .catch((err) => {
                console.log("err", err)
            });

        // /questions/service
        axios.get(`${API_URL_DATABASE}/customize/questions/service`, { headers: { "authorization": localStorage.getItem('token_auth') } })
            .then((res) => {
                // console.log(res.data)
                const sort = res.data.sort((a,b) => a.questionNo - b.questionNo)
                setFieldValue("questionService", sort, false);
            })
            .catch((err) => {
                console.log("err", err)
            });

        // /questions/product
        axios.get(`${API_URL_DATABASE}/customize/questions/product`, { headers: { "authorization": localStorage.getItem('token_auth') } })
            .then((res) => {
                // console.log(res.data)
                const sort = res.data.sort((a,b) => a.questionNo - b.questionNo)
                setFieldValue("questionProduct", sort, false);
            })
            .catch((err) => {
                console.log("err", err)
            });

    }, [setFieldValue]);

    const handleSubmit = event => {
        event.preventDefault();

        let example = {}
        Object.keys(values).filter((fieldName) => {
            return !["date_string", "questionProduct", "questionService", "formId"].includes(fieldName)
        }).forEach((fieldName) => {
            example[fieldName] = values[fieldName]
        })
        console.log("example", example)

        // axios.post(`${API_URL_DATABASE}/response-voc`, example)
        //     .then(res => {
        //         console.log(res);
        //         setCheckSumbited(true);
        //     }).catch(function (err) {
        //         console.log("err", err)
        //     })

        const toUrl = `${API_URL_DATABASE}/customer-complains-voc/${values.formId}`
        const data = example

        console.log(toUrl, data)

        axios.post(toUrl, data)
            .then((res) => {
                console.log(res);
                setCheckSumbited(true);
            }).catch((err) => {
                console.log("err", err)
            })
    }

    const HandleChangeRate = (rating, keyName) => {
        console.log(rating, keyName)
        setFieldValue(`คะแนน${keyName}`, rating, false);
    }

    if (checkSumbited) {
        return <Redirect to="/thx-page" />
    } else {
        return (
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 font-en font-white" style={{ fontWeight: "bold" }}><img src={Logo} alt="Girl in a jacket" width="80" style={{ borderRadius: "8px", marginRight: "10px" }} />Survey Customer</h1>
                    <div className="d-none d-sm-inline-block btn btn-sm btn-bgblue-black shadow-sm"><i className="far fa-calendar mr-2"></i>{values.date_string}</div>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {/* Product */}
                        <div className="col-sm-6">
                            <div className="card shadow mb-4">
                                {/* <!-- Card Header - Dropdown --> */}
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary font-en">สินค้า [Product]</h6>
                                    <div className="dropdown no-arrow">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </div>
                                </div>
                                {/* <!-- Card Body --> */}
                                <div className="card-body">

                                    {values.questionProduct.map((questionProduct) => {
                                        return (
                                            <div className="row align-items-center">
                                                <div className="col-xl-7 col-lg-12 mb-2">
                                                    <label className="font-weight-bold font-blue">{questionProduct.question}:</label>
                                                </div>
                                                <div className="col-xl-5 mb-2">
                                                    <ReactStars value="0" count={5} onChange={(value) => {
                                                        HandleChangeRate(value, questionProduct.question)
                                                    }} size={35} activeColor="#ffd700" />
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>

                        {/* Service */}
                        <div className="col-sm-6">
                            <div className="card shadow mb-4">
                                {/* <!-- Card Header - Dropdown --> */}
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary font-en">บริการ [Service]</h6>
                                    <div className="dropdown no-arrow">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </div>
                                </div>
                                {/* <!-- Card Body --> */}
                                <div className="card-body">


                                    {values.questionService.map((questionService) => {
                                        return (
                                            <div className="row align-items-center">
                                                <div className="col-xl-7 col-lg-12 mb-2">
                                                    <label className="font-weight-bold font-blue">{questionService.question}:</label>
                                                </div>
                                                <div className="col-xl-5 mb-2">
                                                    <ReactStars value="0" count={5} size={35} activeColor="#ffd700" onChange={(value) => {
                                                        HandleChangeRate(value, questionService.question)
                                                    }} />
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {/* Product */}
                        <div className="col-12">
                            <div className="card shadow mb-4">
                                {/* <!-- Card Header - Dropdown --> */}
                                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 className="m-0 font-weight-bold text-primary font-en">ข้อแนะนำเพิ่มเติม [Advice]</h6>
                                    <div className="dropdown no-arrow">
                                        <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                    </div>
                                </div>
                                {/* <!-- Card Body --> */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xl-2 col-lg-12 mb-2">
                                            <label className="font-weight-bold font-blue white-space">คำแนะนำเพิ่มเติม:</label>
                                        </div>
                                        <div className="col-xl-10 mb-2">
                                            <Field as="textarea" name="remark" className="edit w-100" style={{ resize: "none" }} rows="6" />
                                        </div>
                                    </div>

                                    <p className="reamrkStar">5 ดาว = ดีมาก, 4 ดาว = ดี, 3 ดาว = ปานกลาง, 2 ดาว = แย่, 1 ดาว = แย่มาก</p>
                                    <button type="submit" className="btn-blue px-5 float-right">ส่งความคิดเห็น</button>
                                    <p className="reamrkStar float-left">QF-SA-12 : Rev 00 : 01/10/62</p>

                                </div>
                            </div>
                        </div>

                    </div>
                </form>

            </div>
        )
    }
};

export default Content;