import React from 'react';
import Content from "./content.js";

const ThxPage = (props) => {
    return (
        <div id="wrapper">
            {/* <NavSideBar /> */}
            {/* {/*<!-- Content Wrapper -->*/}
            <div id="content-wrapper" className="d-flex flex-column">
                {/*<!-- Begin Page Content -->*/}
                <div id="content" className="container-fluid bg-page"><Content /></div>
                {/*<!-- /.container-fluid -->*/}
            </div>
        </div>
    )
};

export default ThxPage;