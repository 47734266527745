import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { Field, Form, ErrorMessage } from 'formik';
import Letter from '../../../images/letter.svg';

const Content = () => {
    const { values } = useFormikContext();
    let history = useHistory();

    if (values.checkSuccess) {
        return <Redirect to="/"></Redirect>
    } else {
        return (
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 font-en font-white" style={{ fontWeight: "bold" }}><img alt='plus' src={Letter} width="30px" className="mr-4" />Create Customer</h1>
                    <div className="d-none d-sm-inline-block btn btn-sm btn-bgblue-black shadow-sm"><i className="far fa-calendar mr-2"></i>{values.date_string}</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card shadow mb-4">
                            {/* <!-- Card Header - Dropdown --> */}
                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary font-en">Create Customer</h6>
                                <div className="dropdown no-arrow">
                                    <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                </div>
                            </div>
                            {/* <!-- Card Body --> */}
                            <div className="card-body">
                                <Form>
                                    <div className="row">
                                        <div className="col-xl-2 col-lg-12 mb-2">
                                            <label className="font-weight-bold font-blue">ชื่อบริษัท/Co. Name:
                                        <ErrorMessage name="coNameTH" render={msg => <span className="error">{msg}</span>} />
                                                <ErrorMessage name="coNameEN" render={msg => <span className="error">{msg}</span>} />
                                            </label>
                                        </div>
                                        <div className="col-xl-4 mb-2">
                                            <Field className="w-100" type="text" name="coNameTH" placeholder="ภาษาไทย"></Field>
                                        </div>
                                        <div className="col-xl-4 mb-2">
                                            <Field className="w-100" type="text" name="coNameEN" placeholder="ภาษาอังกฤษ"></Field>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-2 col-lg-12 mb-2">
                                            <label className="font-weight-bold font-blue">อีเมลล์/Co. Email:
                                        <ErrorMessage name="email" render={msg => <span className="error">{msg}</span>} />
                                            </label>
                                        </div>
                                        <div className="col-xl-4 mb-2">
                                            <Field className="w-100" type="email" name="email" placeholder="example@example.com"></Field>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-2 col-lg-12 mb-2">
                                            <label className="font-weight-bold font-blue">ชื่อลูกค้า/Name
                                        <ErrorMessage name="name" render={msg => <span className="error">{msg}</span>} />
                                            </label>
                                        </div>
                                        <div className="col-xl-4 mb-2">
                                            <Field className="w-100" type="text" name="name" placeholder="..."></Field>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-xl-2 col-lg-12 mb-2 white-space">
                                            <label className="font-weight-bold font-blue">เบอร์โทรศัพท์/Phone No:
                                        <ErrorMessage name="phone" render={msg => <span className="error">{msg}</span>} />
                                            </label>
                                        </div>
                                        <div className="col-xl-4 mb-2">
                                            <Field className="w-100" type="text" name="phone" placeholder="XXX-XXX-XXXX"></Field>
                                        </div>
                                    </div>

                                    <button type="button" className="btn-blue float-right px-5 ml-3" onClick={() => history.push("/")}>กลับ</button>
                                    <button type="submit" className="btn-blue float-right px-5">บันทึก</button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default Content;