import React from 'react';
import Content from "./content.js";
import { withFormik } from 'formik';

const FromVoc = (props) => {
    return (
        <div id="wrapper">
            {/* <NavSideBar /> */}
            {/* {/*<!-- Content Wrapper -->*/}
            <div id="content-wrapper" className="d-flex flex-column">
                {/*<!-- Begin Page Content -->*/}
                <div id="content" className="container-fluid bg-page"><Content /></div>
                {/*<!-- /.container-fluid -->*/}
            </div>
        </div>
    )
};

const date = new Date();
const ye = date.getFullYear();
const moString = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
const da = date.getDate();
const send_date = new Date().toISOString().slice(0, 10);

const EnhancedFromVoc = withFormik({
    mapPropsToValues: (props) => ({
        questionService: [],
        questionProduct: [],

        coNameTH: '',
        coNameEN: '',
        email: '',
        name: '',
        phone: '',
        remark: '',
        refPO: '',
        date_string: `${da}-${moString}-${ye}`,
        send_date: send_date,
    })
})(FromVoc);

export default EnhancedFromVoc;