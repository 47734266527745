import React, { useState } from "react";
import NavSideBar from "../common/nav-side-bar.js";
import Content from "./content.js";
import { withFormik } from "formik";
import axios from "axios";

import { API_URL_DATABASE } from "../../config_url";

const Main = (props) => {
  const [listCustomer, setListCustomer] = useState([]);
  const onConfirm = (order) => {
    console.log("order", order);
    setListCustomer(order);
  };
  return (
    <div id="wrapper">
      <NavSideBar valueCompony={onConfirm} />
      {/* {/*<!-- Content Wrapper -->*/}
      <div id="content-wrapper" className="d-flex flex-column">
        {/*<!-- Begin Page Content -->*/}
        <div id="content" className="container-fluid bg-page">
          <Content listCustomer={listCustomer} />
        </div>
        {/*<!-- /.container-fluid -->*/}
      </div>
    </div>
  );
};

const date = new Date();
const ye = date.getFullYear();
const moString = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
const da = date.getDate();

const EnhancedMain = withFormik({
  mapPropsToValues: () => ({
    listComplaint: [],

    _id: "",

    coNameTH: "",
    coNameEN: "",
    email: "",
    name: "",
    phone: "",
    refPO: "",
    date_string: `${da}-${moString}-${ye}`,

    checked: false,
  }),
  validate: (values) => {
    const errors = {};

    if (!values.coNameTH) {
      errors.coNameTH = "*";
    }
    if (!values.coNameEN) {
      errors.coNameEN = "*";
    }
    if (!values.email) {
      errors.email = "*";
    }
    if (!values.name) {
      errors.name = "*";
    }
    if (!values.phone) {
      errors.phone = "*";
    }
    if (!values.refPO) {
      errors.refPO = "*";
    }

    return errors;
  },
  handleSubmit: (values, { props }) => {
    const custData = {
      coNameTH: values.coNameTH,
      coNameEN: values.coNameEN,
      name: values.name,
      email: values.email,
      phone: values.phone,
      refPO: values.refPO,
    };

    const toUrl = `${API_URL_DATABASE}/response-form`;
    const data = {
      ...custData,
      belongToCustomerVocId: values._id || null, // documentId of customer
      refPO: values.refPO, // PO
    };
    console.log(toUrl);
    console.log(JSON.stringify(data));
    axios
      .post(toUrl, data, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
        console.log("got", res.data);

        const newFormId = res.data;
        props.history.push(`/preview-email?formId=${newFormId}`);
      })
      .catch((err) => {
        console.log("err", err.response);
      });
    return;
  },
})(Main);

export default EnhancedMain;
