/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withFormik, useFormikContext } from "formik";
import Content from "./content.js";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { API_URL_DATABASE } from "../../config_url.js";

const CustomizeQuestions = () => {
  const { values, setFieldValue } = useFormikContext();

  // /customize/questions/product
  useEffect(() => {
    let findIndex = 0;
    axios
      .get(`${API_URL_DATABASE}/customize/questions/product`, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
          const sort = res.data.sort((a,b) => a.questionNo - b.questionNo)
          sort.map((data) => {
          findIndex = data.questionNo;
          setFieldValue(`product-${data.questionNo}`, data.question, false);
        });
        setFieldValue("lastIndexProduct", parseInt(findIndex) + 1, false);
        setFieldValue("questionProduct", res.data, false);
        setFieldValue("fetchQuestionPro", false, false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [values.fetchQuestionPro]);

  // /customize/questions/service
  useEffect(() => {
    let findIndex = 0;
    axios
      .get(`${API_URL_DATABASE}/customize/questions/service`, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
        const sort = res.data.sort((a,b) => a.questionNo - b.questionNo)
        sort.map((data) => {
          findIndex = data.questionNo;
          setFieldValue(`service-${data.questionNo}`, data.question, false);
        });
        setFieldValue("lastIndexService", parseInt(findIndex) + 1, false);
        setFieldValue("questionService", res.data, false);
        setFieldValue("fetchQuestionSer", false, false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [values.fetchQuestionSer]);

  if (!localStorage.getItem("token_auth")) {
    return <Redirect to="/login"></Redirect>;
  } else {
    return (
      <div id="wrapper">
        {/* <NavSideBar /> */}
        {/* {/*<!-- Content Wrapper -->*/}
        <div id="content-wrapper" className="d-flex flex-column">
          {/*<!-- Begin Page Content -->*/}
          <div id="content" className="container-fluid bg-page">
            <Content />
          </div>
          {/*<!-- /.container-fluid -->*/}
        </div>
      </div>
    );
  }
};

const date = new Date();
const ye = date.getFullYear();
const moString = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
const da = date.getDate();

const EnhancedCustomizeQuestions = withFormik({
  mapPropsToValues: () => ({
    questionProduct: [],
    questionService: [],
    date_string: `${da}-${moString}-${ye}`,
    lastIndexProduct: 0,
    fetchQuestionPro: false,

    lastIndexService: 0,
    fetchQuestionSer: false,
  }),
  handleSubmit: (values, { setFieldValue }) => {
    if (!values.index) {
      const user = {
        index: parseInt(values.lastIndex),
        name_co_th: values.coNameTH,
        name_co_en: values.coNameEN,
        name: values.name,
        phone_no: values.phone,
        email: values.email,
      };
      axios
        .post(`${API_URL_DATABASE}/customer-voc`, user, {
          headers: { authorization: localStorage.getItem("token_auth") },
        })
        .then((res) => {
          console.log(res);
          setFieldValue("checkSuccess", true, false);
        })
        .catch(function (err) {
          console.log("err", err);
        });
    } else {
      // UPDATE DATA
      console.log("update data");
      const user = {
        index: parseInt(values.index),
        name_co_th: values.coNameTH,
        name_co_en: values.coNameEN,
        name: values.name,
        phone_no: values.phone,
        email: values.email,
      };
      axios
        .put(`${API_URL_DATABASE}/customer-voc/${values.index}`, user, {
          headers: { authorization: localStorage.getItem("token_auth") },
        })
        .then((res) => {
          console.log(res);
          setFieldValue("checkSuccess", true, false);
        })
        .catch(function (err) {
          console.log("err", err);
        });
    }
  },
})(CustomizeQuestions);

export default EnhancedCustomizeQuestions;
