import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useFormikContext } from "formik";
import { Field } from "formik";
import Letter from "../../../images/letter.svg";
import { API_URL_DATABASE } from "../../config_url.js";
import axios from "axios";

const Content = () => {
  const { values, setFieldValue } = useFormikContext();
  let history = useHistory();
  // Product
  const handleAddQuestionProduct = () => {
    let data = {
      questionNo: values.lastIndexProduct,
      question: "",
    };
    axios
      .post(`${API_URL_DATABASE}/customize/questions/product`, data, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
        setFieldValue("fetchQuestionPro", true, false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUpdateQuestionProduct = (questionNo, questionValue) => {
    let data = {
      question: questionValue,
    };
    axios
      .put(
        `${API_URL_DATABASE}/customize/questions/product/${questionNo}`,
        data,
        { headers: { authorization: localStorage.getItem("token_auth") } }
      )
      .then((res) => {
        console.log("edited");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleRemoveQuestionProduct = (questionNo) => {
    axios
      .delete(`${API_URL_DATABASE}/customize/questions/product/${questionNo}`, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
        console.log("delte");
        setFieldValue("fetchQuestionPro", true, false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  // Service
  const handleAddQuestionService = () => {
    let data = {
      questionNo: values.lastIndexService,
      question: "",
    };
    axios
      .post(`${API_URL_DATABASE}/customize/questions/service`, data, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
        setFieldValue("fetchQuestionSer", true, false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUpdateQuestionService = (questionNo, questionValue) => {
    let data = {
      question: questionValue,
    };
    axios
      .put(
        `${API_URL_DATABASE}/customize/questions/service/${questionNo}`,
        data,
        { headers: { authorization: localStorage.getItem("token_auth") } }
      )
      .then((res) => {
        console.log("edited");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleRemoveQuestionService = (questionNo) => {
    axios
      .delete(`${API_URL_DATABASE}/customize/questions/service/${questionNo}`, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
        console.log("delte");
        setFieldValue("fetchQuestionSer", true, false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  if (values.checkSuccess) {
    return <Redirect to="/"></Redirect>;
  } else {
    return (
      <div>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1
            className="h3 mb-0 font-en font-white"
            style={{ fontWeight: "bold" }}
          >
            <img alt="plus" src={Letter} width="30px" className="mr-4" />
            Customize Questions
          </h1>
          <div className="d-none d-sm-inline-block btn btn-sm btn-bgblue-black shadow-sm">
            <i className="far fa-calendar mr-2"></i>
            {values.date_string}
          </div>
        </div>

        <div className="row">
          {/* Product */}
          <div className="col-xl-6 col-lg-12">
            <div className="card shadow mb-4">
              {/* <!-- Card Header - Dropdown --> */}
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary font-en">
                  สินค้า [Product]
                </h6>
                <div className="dropdown no-arrow">
                  <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </div>
              </div>
              {/* <!-- Card Body --> */}
              <div className="card-body">
                {values.questionProduct.map((questionProduct, index) => {
                  return (
                    <div className="row">
                      <div className="col-xl-2 col-lg-12 mb-2">
                        <label className="font-weight-bold font-blue">
                          ข้อที่ {index + 1}:
                        </label>
                      </div>
                      <div className="col-xl-9 mb-2">
                        <Field
                          className="w-100"
                          type="text"
                          name={`product-${questionProduct.questionNo}`}
                          placeholder="ภาษาไทย"
                          onChange={(e) => {
                            setFieldValue(
                              `product-${questionProduct.questionNo}`,
                              e.target.value,
                              false
                            );
                            handleUpdateQuestionProduct(
                              questionProduct.questionNo,
                              e.target.value
                            );
                          }}
                        ></Field>
                      </div>
                      <div className="col-xl-1 mb-2">
                        <button
                          type="button"
                          className="btn-red"
                          onClick={() =>
                            handleRemoveQuestionProduct(
                              questionProduct.questionNo
                            )
                          }
                        >
                          ลบ
                        </button>
                      </div>
                    </div>
                  );
                })}

                <button
                  type="button"
                  className="btn-green px-3 float-right"
                  onClick={() => handleAddQuestionProduct()}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </div>

          {/* Service */}
          <div className="col-xl-6 col-lg-12">
            <div className="card shadow mb-4">
              {/* <!-- Card Header - Dropdown --> */}
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary font-en">
                  บริการ [Service]
                </h6>
                <div className="dropdown no-arrow">
                  <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                </div>
              </div>
              {/* <!-- Card Body --> */}
              <div className="card-body">
                {values.questionService.map((questionService, index) => {
                  return (
                    <div className="row">
                      <div className="col-xl-2 col-lg-12 mb-2">
                        <label className="font-weight-bold font-blue">
                          ข้อที่ {index + 1}:
                        </label>
                      </div>
                      <div className="col-xl-9 mb-2">
                        <Field
                          className="w-100"
                          type="text"
                          name={`service-${questionService.questionNo}`}
                          placeholder="ภาษาไทย"
                          onChange={(e) => {
                            setFieldValue(
                              `service-${questionService.questionNo}`,
                              e.target.value,
                              false
                            );
                            handleUpdateQuestionService(
                              questionService.questionNo,
                              e.target.value
                            );
                          }}
                        ></Field>
                      </div>
                      <div className="col-xl-1 mb-2">
                        <button
                          type="button"
                          className="btn-red"
                          onClick={() =>
                            handleRemoveQuestionService(
                              questionService.questionNo
                            )
                          }
                        >
                          ลบ
                        </button>
                      </div>
                    </div>
                  );
                })}
                <button
                  type="button"
                  className="btn-green px-3 float-right"
                  onClick={() => handleAddQuestionService()}
                >
                  เพิ่ม
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button
              className="btn-blue float-right px-5 py-1 mb-3"
              type="button"
              onClick={() => history.push("/")}
            >
              กลับ
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default Content;
