import React, { useState} from 'react';
import Logo from '../../../images/logo-inno.png'

const Content = (props) => {
    const [date, ] = useState(new Date());
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
    return (
        <div>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 font-en font-white" style={{ fontWeight: "bold" }}><img src={Logo} alt="Girl in a jacket" width="80" style={{ borderRadius: "8px", marginRight: "10px" }} />Survey Customer</h1>
                <div className="d-none d-sm-inline-block btn btn-sm btn-bgblue-black shadow-sm"><i className="far fa-calendar mr-2"></i>{`${da}-${mo}-${ye}`}</div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 className="m-0 font-weight-bold text-primary font-en">Survey Customer</h6>
                            <div className="dropdown no-arrow">
                                <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                            </div>
                        </div>
                        {/* <!-- Card Body --> */}
                        <div className="card-body">
                            <h1 className="font-black text-primary" style={{ textAlign: "center", margin: "10rem 0" }}>ขอบคุณที่ใช้บริการ Innovation Tech Co.,LTD :)</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Content;