import React, { useEffect, useState } from "react";
import Logo from "../../../images/logo-inno.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_URL_DATABASE } from "../../config_url.js";

const PreviewEmail = (props) => {
  const [coNameTH, setCoNameTH] = useState("");
  const [coNameEN, setCoNameEN] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [refPO, setRefPO] = useState("");
  const [formId, setFormId] = useState("");

  useEffect(() => {
    let url = window.location.search;
    const urlParams = new URLSearchParams(url);
    const nFormId = urlParams.get("formId");
    setFormId(nFormId);
    axios
      .get(`${API_URL_DATABASE}/customer-voc/${nFormId}`, {
        headers: { authorization: localStorage.getItem("token_auth") },
      })
      .then((res) => {
        setCoNameTH(res.data.coNameTH);
        setCoNameEN(res.data.coNameEN);
        setEmail(res.data.email);
        setName(res.data.name);
        setPhone(res.data.phone);
        setRefPO(Object.keys(res.data.has_PO).find(key => res.data.has_PO[key] === nFormId));
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div className="form-preview">
      <div className="container-fluid pt-5">
        <div
          className="row pt-5 pb-2"
          style={{ textAlign: "center", backgroundColor: "#196BEF" }}
        >
          <div className="col-12">
            <img
              src={Logo}
              alt="Girl in a jacket"
              width="150"
              style={{ borderRadius: "8px" }}
            />
          </div>
        </div>
        <div
          className="row"
          style={{ textAlign: "center", backgroundColor: "#EEF2F8" }}
        >
          <div className="col-12">
            <h2 className="font-black">Innovation Tech Co., Ltd.</h2>
            <h3 className="font-black">บริษัท อินโนเวชั่นเทค จำกัด</h3>
            <p className="font-black">
              เรียนคุณลูกค้า {name} จากบริษัท {coNameTH}/{coNameEN}
            </p>
            <p className="font-black">
              ขอขอบคุณที่ใช้ผลิตภัณฑ์ของอินโนเวชั่นเทค
            </p>
            <p className="font-black">
              ทางเราขอสอบถามเกี่ยวกับผลิตภัณฑ์และบริการของเราเพื่อนำไปปรับปรุงการบริการสำหรับผลิตภัณฑ์ของเราต่อไป
            </p>
            <p className="font-black">อ้างอิง PO: {refPO}</p>
          </div>
        </div>
        <div
          className="row pt-2 pb-5"
          style={{ textAlign: "center", backgroundColor: "#EEF2F8" }}
        >
          <div className="col-12">
            <Link to={`/from-voc?formId=${formId}`}>
              <button type="button" className="btn-blue px-5">
                กรอกแบบสอบถาม
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewEmail;
