import React from "react";
import Content from "./content.js";
import { withFormik } from "formik";
import axios from "axios";
import { API_URL_DATABASE } from "../../config_url.js";

const LoginComponent = (props) => {
  return <Content />;
};

const EnhancedLoginComponent = withFormik({
  mapPropsToValues: (props) => ({
    username: "",
    password: "",

    checkLogin: false,
  }),
  validate: (values) => {
    const errors = {};

    if (values.username === "") {
      errors.username = " *กรุณากรอกอีเมล";
    }

    if (values.password === "") {
      errors.password = " *กรุณากรอกใส่รหัสผ่าน";
    }

    return errors;
  },
  handleSubmit: (values, { setFieldValue, props }) => {
    const user = {
      username: values.username,
      password: values.password,
    };
    console.log("user", user, "API_URL_DATABASE", API_URL_DATABASE);
    axios
      .post(`${API_URL_DATABASE}/login`, user)
      .then((res) => {
        console.log(res);
        if (res.data !== "User not found") {
          localStorage.setItem("token_auth", res.data.token);
          props.history.push("/");
        } else {
          setFieldValue("checkLogin", true, false);
        }
      })
      .catch(function (err) {
        console.log("err", err.response);
        setFieldValue("checkLogin", true, false);
      });
  },
})(LoginComponent);

export default EnhancedLoginComponent;
